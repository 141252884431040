<template>
    <section>
        <div class="container-scroller">
            <div class="container-fluid page-body-wrapper full-page-wrapper">
                <div class="content-wrapper d-flex align-items-center auth">
                    <div class="row w-100 flex-grow">
                        <div class="col-xl-4 col-lg-6 mx-auto">
                            <div class="auth-form-light text-left p-5 my_auth text-center">
                                <div class="brand-logo">
                                    <img class="logo_image" src="@/assets/images/import/logo-mini.png">
                                </div>
                                <h4>Ne ratez plus les nouveautés sur le site!</h4>
                                <!-- <h6 class="font-weight-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti nihil nostrum, blanditiis quibusdam perferendis odit commodi perspiciatis eos vitae explicabo dicta est nam fuga facere voluptatum sequi ducimus quos placeat.</h6> -->
                                
                                <!-- newsletter -->
                                <b-form-group class="mt-3" label="" label-for="newsletter">
                                    <b-form-checkbox
                                    id="newsletter"
                                    v-model="newsletter"
                                    name="newsletter"
                                    >
                                    <p>Oui, je souhaite profiter pleinement de Mon échappée belle et recevoir des e-mails comportant des offres exclusives, des recommandations personnalisées et des astuces ! </p> 
                                    </b-form-checkbox>
                                </b-form-group>

                                <button class="btn btn-primary btn-block" @click="submitNewsletter()">Modifier</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
export default {
    name: "newsletter",
    data() {
        return {
            newsletter: true
        }
    },
    methods: {
        submitNewsletter(){
            let ulid = localStorage.getItem('ulid')
            axios.put(`myinfos/${ulid}`,
            {
                newsletter : this.newsletter
            },
            {   
                headers: {
                    "X-AUTH-TOKEN": localStorage.getItem('token')
                }
            })
            .then(resNewsletter => {
                console.log(resNewsletter)
            })
            .catch(errNewsletter => console.log(errNewsletter))
        }
    },
    mounted () {
    }
}
</script>